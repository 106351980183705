<template>
  <div class="row">
    <div class="col-12 col-lg-6 c g">
        <div class="card">
            <div class="card-header">
                <h5>
                    كود التسويق الخاص بك 
                </h5>
            </div>
            <div class="card-body">
                <div class="form-group">
                  <input type="text" readonly id="code"
                    class="form-control form-control-lg" style='text-align: center' :value="'https://s.tahdir.net/register#' + user.code">
                </div>
            </div>
        </div>
    </div>
    <div class="col-12"></div>
    <div class="col-12 col-lg-3 g">
        <div class="card">
            <div class="card-body text-center">
                <h1>
                    {{ users.length }}
                </h1>
            </div>
            <div class="card-footer text-center">
                <h5>
                    المسجلين من خلالك
                </h5>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-3 g">
        <div class="card">
            <div class="card-body text-center">
                <h1>
                    {{ orders.length }}
                </h1>
            </div>
            <div class="card-footer text-center">
                <h5>
                    اجمالي الطلبات
                </h5>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-3 g">
        <div class="card">
            <div class="card-body text-center">
                <h1>
                    {{ withdrawal.toFixed(1) }} ريال
                </h1>
            </div>
            <div class="card-footer text-center">
                <h5>
                    الرصيد القابل للسحب
                </h5>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-3 g">
        <div class="card">
            <div class="card-body text-center">
                <h1>
                    {{ withdrawed.toFixed(1) }} ريال
                </h1>
            </div>
            <div class="card-footer text-center">
                <h5>
                    الرصيد المسحوب
                </h5>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-6 g">
        <div class="card">
            <div class="card-header">
                سجل المستخدمين
            </div>
            <div class="card-body">
                <div class="col-12 table-responsive">
                    <table class="table table-hover table-bordered table-sm">
                        <thead>
                            <th>المستخدم</th>
                            <th>
                                تاريخ الانضمام
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="u in users" :key="u._id">
                                <td>
                                    {{ u.name }}
                                </td>
                                <td>
                                    {{ u.joindate }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-6 g">
        <div class="card">
            <div class="card-header">
                سجل الطلبات
            </div>
            <div class="card-body">
                <div class="col-12 table-responsive">
                    <table class="table table-hover table-bordered table-sm">
                        <thead>
                            <th>التاريخ</th>
                            <th>
                                الخدمة
                            </th>
                            <th>
                                المبلغ
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="u in orders" :key="u._id">
                                <td>
                                    {{ u.date }}
                                </td>
                                <td>
                                    {{ u.service }}
                                </td>
                                <td>
                                    {{ u.original * u.reseller_percent }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-3 g" v-if="user.full">
        <div class="card">
            <div class="card-body text-center">
                <h1>
                    {{ tiporders.length }}
                </h1>
            </div>
            <div class="card-footer text-center">
                <h5>
                    عدد العمولات الخارجية
                </h5>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-3 g" v-if="user.full">
        <div class="card">
            <div class="card-body text-center">
                <h1>
                    {{ totaltip }} ريال
                </h1>
            </div>
            <div class="card-footer text-center">
                <h5>
                    اجمالي العمولات الخارجية
                </h5>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-6 g c" v-if="user.full">
        <div class="card">
            <div class="card-header">
                سجل العمولات الخارجية
            </div>
            <div class="card-body">
                <div class="col-12 table-responsive">
                    <table class="table table-hover table-bordered table-sm">
                        <thead>
                            <th>التاريخ</th>
                            <th>
                                التفاصيل
                            </th>
                            <th>
                                المبلغ
                            </th>
                            <th>
                                الحالة
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="u in tiporders" :key="u._id">
                                <td>
                                    {{ u.date }}
                                </td>
                                <td>
                                    {{ u.details }}
                                </td>
                                <td>
                                    {{ u.amount }}
                                </td>
                                <td>
                                    {{ u.done ? 'تم السحب' : 'لم يتم السحب' }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            withdrawal: 0,
            withdrawed: 0,
            users: [],
            orders: [],
            tiporders: [],
            totaltip: 0
        }
    },
    created(){
        setTimeout(() => {
            $("#code").click(function(){
                var input = document.getElementById("code");
                input.focus();
                input.select();
            })
        }, 100);
        var g = this;
        $.post(api + '/reseller/stats', {
            jwt: this.user.jwt
        }).then(function(r){
            g.withdrawal = r.response.withdrawal;
            g.withdrawed = r.response.withdrawed;
            g.users = r.response.users;
            g.orders = r.response.orders;
            g.tiporders = r.response.tips
            g.totaltip = r.response.totaltip
        })
    }
}
</script>

<style>

</style>